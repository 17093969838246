/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 * https://github.com/LekoArts/gatsby-starter-prismic/blob/master/src/components/SEO/SEO.jsx
 */

import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useAppSelector } from '@hooks/redux-hooks';
import useTranslation from '@hooks/useTranslation';
import { ThemeContext } from 'styled-components';
import getSymbol from '@utils/getSymbol';

/** access the meat of our symbol data based on the filename. The filename must correspond to our languages */
const metaImageLanguagedSymbolDigger = (
  s: object,
  currentLanguage: string,
): string => {
  const nodes = s?.nodes?.[0]?.data?.Content[0]?.data?.Symbol?.localFiles;
  if (!nodes) {
    return '';
  }
  if (nodes?.length > 0) {
    // return the item that matches with our default language setting
    const currentLanguageNode = nodes.find(e => e.name === currentLanguage);
    if (currentLanguageNode) {
      return currentLanguageNode.publicURL;
    }
  }
  return nodes[0].publicURL;
};

const FacebookMeta = ({ url, name, type, title, desc, image, locale }) => (
  <Helmet>
    {name && <meta property="og:site_name" content={name} />}
    <meta property="og:locale" content={locale} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={desc} />
    <meta property="og:image" content={image} />
    <meta property="og:image:alt" content={desc} />
  </Helmet>
);

const TwitterMeta = ({ type, username, title, desc, image }) => (
  <Helmet>
    {username && <meta name="twitter:creator" content={username} />}
    <meta name="twitter:card" content={type} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={desc} />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:image:alt" content={desc} />
  </Helmet>
);

interface SeoProps {
  title?: string;
  description?: string;
  imageUrl?: string;
}
const Seo = ({ title, description, imageUrl }: SeoProps) => {
  const { site, metaSymbols, faviconSymbol } = useStaticQuery(
    graphql`
      query siteMetaQuery {
        metaSymbols: allAirtable(
          filter: {
            table: { eq: "Game Elements" }
            data: { Name: { eq: "Default Meta Info" } }
          }
        ) {
          ...AbsoluteGetFragment
        }
        faviconSymbol: allAirtable(
          filter: {
            table: { eq: "Game Elements" }
            data: { Name: { eq: "Favicon" } }
          }
        ) {
          ...AbsoluteGetFragment
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );

  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const metaTitle = title || t('Main Title');
  const { defaultLanguage: metaLang } = useAppSelector(
    state => state.gameState,
  );

  const metaDescription = description || t('Default Meta Info');
  // the url env var comes from netlify built-in
  const baseUrl = process.env?.URL || '';
  const metaImageUrl =
    baseUrl +
    (imageUrl || metaImageLanguagedSymbolDigger(metaSymbols, metaLang));
  const metaUrl = site?.siteMetadata?.siteUrl;
  const { data: faviconUrl } = getSymbol(faviconSymbol, true);
  return (
    <>
      <Helmet title={metaTitle}>
        <html lang={metaLang} />
        <link rel="shortcut icon" type="image/png" href={faviconUrl} />
        <meta name="description" content={metaDescription} />
        <meta name="image" content={metaImageUrl} />
        <meta
          name="theme-color"
          content={themeContext.colors.primary || '#ffffff'}
        />
      </Helmet>
      <FacebookMeta
        desc={metaDescription}
        image={metaImageUrl}
        title={metaTitle}
        type="website"
        url={metaUrl}
        locale={metaLang}
        name={metaTitle}
      />
      <TwitterMeta
        desc={metaDescription}
        image={metaImageUrl}
        title={metaTitle}
        type="website"
      />
    </>
  );
};

export default Seo;
